




























































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { toReadableDate } from '@/utils/time'
import { pluralize } from '@/utils/misc'
import { ADORI_API_BASE_URL, ADORI_PODCASTS_BASE_URL } from '@/constants'
import YoutubeUploadStatus from '../Youtube/YoutubeUploadStatus.vue'
import { secondsToHMS } from '@/components/Youtube/time'
import ButtonAudioAdorify from '@/components/Common/Buttons/ButtonAudioAdorify.vue'

// modals
import ShareModal from '@/components/Audio/Modals/Share.vue'

import { audioQueryKeys, useGetVideoClipsByTrackId } from '@/hooks/audio'
import { QueryClient, useQueryClient } from 'vue-query'
import { computed } from '@vue/composition-api'

import get from 'lodash.get'
import moment from 'moment'

@Component({
  components: {
    ShareModal,
    YoutubeUploadStatus,
    ButtonAudioAdorify,
  },
  setup(props: any) {
    const audioTrackId: any = computed(() => props.audioTrack.uid)
    const queryClient = useQueryClient()
    const { data: videoClipsData, isLoading: videoClipsLoading } = useGetVideoClipsByTrackId(audioTrackId)

    return {
      queryClient,
      videoClipsData,
      videoClipsLoading,
    }
  },
})
export default class AudioTrackV2 extends Vue {
  @Prop() audioTrack!: any
  @Prop(Boolean) selectable!: boolean
  @Prop(String) selectionType!: string // One of 'SINGLE' or 'MULTI'
  @Prop(Boolean) editable!: boolean
  @Prop(Boolean) showEditButtons!: boolean
  @Prop(Boolean) showPreviewButton!: boolean
  @Prop(Boolean) showDownloadButton!: boolean
  @Prop(String) sourceComponent!: string
  @Prop(Boolean) isPublishPage!: boolean
  @Prop() podcast!: any

  @Prop(String) collectionType!: string

  @Getter networkId!: any
  @Getter token!: any
  @Getter signinStatus!: any
  @Getter publicFeedEpisodes!: any
  @Getter tagPositions!: any
  @Getter rssFeedAudioTrack!: any

  @Action showEditPodcastEpisode!: any
  @Action closeModal!: any
  @Action deleteYoutubeAudio!: any
  @Action pushNotification!: any
  @Action getPublicFeedEpisodes!: any
  @Action showAudioTrackEditor!: any
  @Action setShowLoader!: any
  @Action deleteAudio!: any
  @Action showIframeEmbed!: any
  @Action showReplaceAudio!: any
  @Action removeRssFeedItem!: any
  @Action publishRssFeed!: any
  @Action clearAllRssFeedItemsByFeedUid!: any
  @Action updateRssFeedItems!: any

  orginal = 'ORIGINAL'

  shareAudio = false
  isComboBoxActive = false

  original = 'ORIGINAL'

  videoClipsData!: any
  videoClipsLoading!: any
  queryClient!: QueryClient

  mounted() {
    this.$store.commit('addAudio', this.audioTrack)
    sessionStorage.getItem(this.audioTrack.uid) === this.audioTrack.uid &&
      this.queryClient.invalidateQueries(audioQueryKeys.TRACKVIDEOCLIPS)
    sessionStorage.removeItem(this.audioTrack.uid)
  }

  get trackTags() {
    return this.tagPositions(this.audioTrack.uid)
  }

  get youtubeSignInStatus() {
    if (get(this.audioTrack, 'ytFeed') === null) {
      return get(this.signinStatus, `[${this.networkId}].youtube`, false)
    } else {
      return (
        get(this.signinStatus, `[${get(this.podcast, 'uid', '')}].youtube`, false) ||
        get(this.signinStatus, `[${this.networkId}].youtube`, false)
      )
    }
  }

  get audioPreviewImage() {
    return this.audioTrack && this.audioTrack.imageInfo ? this.audioTrack.imageInfo.thumbnailURL : null
  }

  get audioDate() {
    return toReadableDate(this.audioTrack.createdOn)
  }

  get audioDuration() {
    if (this.audioTrack.durationMillis) {
      return secondsToHMS(this.audioTrack.durationMillis / 1000)
    }
    return 'Calculating...'
  }

  get firstClip() {
    return this.videoClipsData?.data[0]
  }

  get allVideoClipsExceptFirst() {
    return this.videoClipsData?.data.slice(1)
  }

  get audioTagsText() {
    if (!!this.trackTags) {
      return this.trackTags.length
        ? `${this.trackTags.length} ${pluralize(this.trackTags.length, 'tag', 'tags')} added`
        : 'No tags added'
    }
    if (!this.audioTrack?.tagCount) return 'No tags added'
    return `${this.audioTrack?.tagCount} ${pluralize(this.audioTrack?.tagCount, 'tag', 'tags')} added`
  }

  get showComboBox() {
    return this.videoClipsData?.count > 1 ? true : false
  }

  get portraitExperienceSrc() {
    if (!this.audioTrack) return `#`
    return `${ADORI_PODCASTS_BASE_URL}/e/${this.slugify(this.audioTrack.name)}?eid=${this.audioTrack.uid}`
  }

  get formatedPublishedOrScheduled() {
    if (this.audioTrack.isScheduled) {
      return 'Scheduled for: ' + moment(this.audioTrack.scheduleTime).format('MMMM Do, YYYY, h:mm a')
    } else if (this.audioTrack.publishedOn) {
      return 'Published on: ' + moment(this.audioTrack.publishedOn).format('MMMM Do, YYYY, h:mm a')
    }
    return null
  }

  get audioIsAdorified() {
    if (!this.audioTrack) return false
    return this.audioTrack && this.audioTrack.adorificationStatus === 'FINISHED'
  }

  get audioIsGettingReplaced() {
    if (!this.audioTrack) return false
    return (
      this.audioTrack.replaceTask &&
      this.audioTrack.replaceTask.status !== 'FINISHED' &&
      this.audioTrack.replaceTask.status !== 'FAILED'
    )
  }

  get audioTrackMenu() {
    const trackMenu = [
      {
        name: 'Create Video',
        icon: 'ondemand_video',
        onClick: () => this.goToOnboarding(),
      },
      {
        name: 'Delete track',
        icon: 'delete',
        onClick: () => this.handleDeleteAudio(),
      },
      {
        name: 'Embed on web',
        icon: 'stay_primary_portrait',
        onClick: () => this.handleEmbedWeb(),
      },
      {
        name: 'Replace audio',
        icon: 'swap_vert',
        onClick: () => this.handleReplaceAudio(),
      },
    ]

    if (this.$permissions.isEditEpisodeShowAllowed(this.podcast?.uid)) {
      trackMenu.splice(1, 0, {
        name: 'Edit Episode',
        icon: 'edit',
        onClick: () => this.handleEditClick(),
      })
    }
    return trackMenu
  }

  get podcastEpisodeMenu() {
    const episodeMenu: any = []
    this.$permissions.isYoutubeConvertVideoShowEpisodeAllowed(this.podcast?.uid)
    this.$permissions.isDeleteEpisodeShowAllowed(this.podcast?.uid) &&
      episodeMenu.push(
        {
          name: this.sourceComponent === 'ALLAUDIO-TAB' ? 'Delete track' : 'Remove episode',
          icon: this.sourceComponent === 'ALLAUDIO-TAB' ? 'delete' : 'clear',
          onClick: () => this.handleRemoveEpisode(),
        },
        {
          name: 'Embed on web',
          icon: 'stay_primary_portrait',
          onClick: () => this.handleEmbedWeb(),
        },
        {
          name: 'Replace audio',
          icon: 'swap_vert',
          onClick: () => this.handleReplaceAudio(),
        },
        {
          name: 'Share Audio',
          icon: 'share',
          onClick: () => this.handleShareAudio(),
        },
        {
          name: 'Download Original Audio',
          icon: 'verified_user',
          onClick: () => (window.location.href = this.audioDownloadURL(this.orginal)),
        },
        {
          name: 'Download Audio',
          icon: 'cloud_download',
          onClick: () => (window.location.href = this.audioDownloadURL()),
        }
      )

    if (this.$permissions.isEditEpisodeShowAllowed(this.podcast?.uid)) {
      episodeMenu.splice(1, 0, {
        name: 'Edit Episode',
        icon: 'edit',
        onClick: () => this.handleEditClick(),
      })
    }
    return episodeMenu
  }

  get selectionIcon() {
    return this.$store.getters.isAudioSelected(this.audioTrack.uid) ? 'check_circle' : 'radio_button_unchecked'
  }

  get selectionStyle() {
    return this.$store.getters.isAudioSelected(this.audioTrack.uid) ? 'adori-red' : 'o-20'
  }

  get podcastsWithAudio() {
    if (this.sourceComponent && this.sourceComponent === 'PODCASTS-TAB') {
      return [this.podcast]
    }
    return (this.audioTrack && this.audioTrack.audioCollections) || []
  }

  get hasSelectedPodcast() {
    return this.podcastsWithAudio.length > 0 ? true : false
  }

  podcastEpisode(podcastUid: string) {
    return this.rssFeedAudioTrack(podcastUid, this.audioTrack.uid)
  }

  truncate(str: string, count: number) {
    if (str.length < count) return str
    return str.substring(0, count) + '...'
  }

  slugify(str: string) {
    return (
      str
        // Trim leading and trailing white space.
        .trim()
        // Turn everthing to lower case.
        .toLowerCase()
        // Remove non-alphanumeric or non-space characters.
        .replace(/[^a-z0-9 ]/g, '')
        // Replace spaces with hyphens.
        .replace(/ /g, '-')
    )
  }

  handleShareAudio() {
    this.shareAudio = true
  }

  closeShare() {
    this.shareAudio = false
  }

  handleRemoveEpisode() {
    if (this.sourceComponent === 'ALLAUDIO-TAB') {
      this.$store.dispatch('showConfirm', {
        title: 'Delete track or remove episode?',
        description: `You are about to remove this episode from following podcasts:<br /><br />${this.podcastsWithAudio
          .map((podcast: any) => podcast.name)
          .join(`<br />`)}<br /><br />Do you want to delete the audio track as well?`,
        yesButtonText: 'Yes, remove episode and delete audio',
        noButtonText: 'No, just remove episode',
        onConfirm: async () => {
          this.setShowLoader(true)
          try {
            for (let index = 0; index < this.podcastsWithAudio.length; index++) {
              await this.removeRssFeedItem({
                rssFeedUid: this.podcastsWithAudio[index].uid,
                rssFeedItemUid: this.audioTrack.uid,
                deleteTrack: true,
              })
              await this.publishRssFeed({
                rssFeedUid: this.podcastsWithAudio[index].uid,
              })
            }
          } catch (err) {
            this.setShowLoader(false)
          }
          this.invalidate()
          this.closeModal()
          this.setShowLoader(false)
        },
        onCancel: async () => {
          this.setShowLoader(true)
          try {
            for (let index = 0; index < this.podcastsWithAudio.length; index++) {
              await this.removeRssFeedItem({
                rssFeedUid: this.podcastsWithAudio[index].uid,
                rssFeedItemUid: this.audioTrack.uid,
              })
              await this.publishRssFeed({
                rssFeedUid: this.podcastsWithAudio[index].uid,
              })
            }
          } catch (err) {
            this.setShowLoader(false)
          }
          this.invalidate()
          this.closeModal()
          this.setShowLoader(false)
        },
      })
    } else {
      if (this.podcastsWithAudio && this.podcastsWithAudio.length > 1) {
        this.$store.dispatch('showConfirm', {
          title: 'Remove episode?',
          description: `Since this episode is part of multiple podcasts you can only remove the episode from this podcast.<br /><br />If you want to permanently delete the audio track please go to all audio tracks section.`,
          yesButtonText: 'Yes, remove episode',
          noButtonText: 'No, keep episode',
          onConfirm: async () => {
            this.setShowLoader(true)
            try {
              await this.removeRssFeedItem({
                rssFeedUid: this.podcast.uid,
                rssFeedItemUid: this.audioTrack.uid,
              })
              await this.publishRssFeed({
                rssFeedUid: this.podcast.uid,
              })
              await this.clearAllRssFeedItemsByFeedUid()
              await this.updateRssFeedItems({
                rssFeedUid: this.podcast.uid,
              })
            } catch (err) {
              this.setShowLoader(false)
            }
            this.invalidate()
            this.closeModal()
            this.setShowLoader(false)
          },
          onCancel: () => {
            this.closeModal()
          },
        })
      } else if (this.podcastsWithAudio && this.podcastsWithAudio.length === 1) {
        this.$store.dispatch('showConfirm', {
          title: 'Remove episode or delete track?',
          description: `You are about to remove the episode from this podcast.<br /><br />Do you want to delete the audio track as well?`,
          yesButtonText: 'Yes, remove episode and delete audio',
          noButtonText: 'No, just remove episode',
          onConfirm: async () => {
            this.setShowLoader(true)
            try {
              await this.removeRssFeedItem({
                rssFeedUid: this.podcast.uid,
                rssFeedItemUid: this.audioTrack.uid,
                deleteTrack: true,
              })
              await this.publishRssFeed({
                rssFeedUid: this.podcast.uid,
              })
              await this.clearAllRssFeedItemsByFeedUid()
              await this.updateRssFeedItems({
                rssFeedUid: this.podcast.uid,
              })
            } catch (err) {
              this.setShowLoader(false)
            }
            this.invalidate()
            this.closeModal()
            this.setShowLoader(false)
          },
          onCancel: async () => {
            this.setShowLoader(true)
            try {
              await this.removeRssFeedItem({
                rssFeedUid: this.podcast.uid,
                rssFeedItemUid: this.audioTrack.uid,
              })
              await this.publishRssFeed({
                rssFeedUid: this.podcast.uid,
              })
            } catch (err) {
              this.setShowLoader(false)
            }
            this.invalidate()
            this.closeModal()
            this.setShowLoader(false)
          },
        })
      }
    }
  }

  handleDeleteAudio() {
    if (this.podcastsWithAudio.length === 0) {
      this.$store.dispatch('showConfirm', {
        title: 'Delete audio track?',
        description: 'You are about to permanently delete this audio track. Are you sure?',
        yesButtonText: 'Yes, delete this audio track',
        noButtonText: 'No, keep this audio track',
        onConfirm: async () => {
          this.setShowLoader(true)
          try {
            await this.deleteAudio(this.audioTrack.uid)
          } catch (err) {
            this.setShowLoader(false)
          }
          this.pushNotification({
            text: 'Episode removed successfully',
            type: 'SUCCESS',
          })
          this.invalidate()
          this.closeModal()
          this.setShowLoader(false)
        },
      })
    } else {
      this.$store.dispatch('showConfirm', {
        title: 'Cannot delete audio track',
        description:
          'You cannot delete this audiotrack as it is part of one or move active podcasts. Please remove it from any podcasts before deleting the audio.',
        yesButtonText: 'Okay, got it',
        onConfirm: () => {
          this.closeModal()
        },
      })
    }
  }

  handleEmbedWeb() {
    this.showIframeEmbed(this.audioTrack.uid)
  }

  handleReplaceAudio() {
    this.showReplaceAudio(this.audioTrack.uid)
  }

  handleEditEpisodeDetails() {
    this.showEditPodcastEpisode(this.audioTrack.uid)
  }
  handleEditAudioDetails() {
    this.showAudioTrackEditor(this.audioTrack)
  }

  handleEditAudio() {
    this.$router.push('/audio-editor?homepage=true&uid=' + this.audioTrack.uid)
  }

  audioDownloadURL(type?: string) {
    const networkId = this.$store.getters.networkId

    return type === this.original
      ? `${ADORI_API_BASE_URL}/networks/${networkId}/audiotracks/${this.audioTrack.uid}/audio/download?api_token=${
          this.token
        }&original=${true}`
      : `${ADORI_API_BASE_URL}/networks/${networkId}/audiotracks/${this.audioTrack.uid}/audio/download?api_token=${this.token}`
  }

  handleIframeEmbed() {
    this.showIframeEmbed(this.audioTrack.uid)
  }

  // Todo: When implementing for Studio continue here
  //   handleAudioClicked() {
  //     if (this.editable) {
  //       this.handleEditAudio()
  //     } else if (this.selectable) {
  //       this.handleAudioSelectionIconClicked()
  //     }
  //   }
  // Todo: When implementing for Studio continue here
  handleAudioSelectionIconClicked() {
    if (this.selectable) {
      this.$store.dispatch('toggleSelectAudio', this.audioTrack.uid)
    }
  }

  async goToOnboarding() {
    const uid = this.$route.params.id
    if (!uid) {
      const selectedEpisode: any = {
        audioUrl: this.audioTrack.audioUrl,
        name: this.audioTrack.name,
        description: this.audioTrack.description,
        explicitContent: this.audioTrack.explicitContent,
        guid: this.audioTrack.guid,
        imageId: this.audioTrack.imageId,
        imageUrl: this.audioTrack.imageUrl,
        isRss: false,
        keywords: this.audioTrack.keywords,
        durationSeconds: this.audioTrack.durationMillis / 1000,
        isReUpload: true,
      }

      this.$store.commit('unselectAllEpisodeForUpload')
      this.$store.commit('clearYoutubeState')
      this.$store.commit('resetYoutubeSetting')
      this.$store.commit('selectEpisodeForUpload', selectedEpisode)
      this.$store.commit('setYoutubeStep', 2)
      // @ts-ignore
      sessionStorage.setItem('upload', 'YES')
      sessionStorage.setItem('onboardingTrack', JSON.stringify(selectedEpisode))
      this.$router.push('/onboarding')
    } else if (this.audioTrack.scheduleTime) {
      sessionStorage.setItem('feedUid', uid)
      const selectedEpisode: any = {
        audioUrl: this.audioTrack.audioUrl,
        name: this.audioTrack.name,
        description: this.audioTrack.description,
        explicitContent: this.audioTrack.explicitContent,
        guid: this.audioTrack.guid,
        imageId: this.audioTrack.imageId,
        imageUrl: this.audioTrack.imageUrl,
        isRss: false,
        keywords: this.audioTrack.keywords,
        durationSeconds: this.audioTrack.durationMillis / 1000,
        isReUpload: true,
      }

      this.$store.commit('unselectAllEpisodeForUpload')
      this.$store.commit('clearYoutubeState')
      this.$store.commit('resetYoutubeSetting')
      this.$store.commit('selectEpisodeForUpload', selectedEpisode)
      this.$store.commit('setYoutubeStep', 2)
      // @ts-ignore
      sessionStorage.setItem('upload', 'YES')
      sessionStorage.setItem('onboardingTrack', JSON.stringify(selectedEpisode))
      this.$router.push('/onboarding')
    } else {
      this.$store.commit('setCreatedYoutubeFeed', { uid: this.podcast.uid })
      await this.getPublicFeedEpisodes({
        url: this.podcast.rss,
      })
      sessionStorage.setItem('feedUid', this.podcast.uid)
      sessionStorage.setItem('feedUrl', this.podcast.rss)
      const selectedEpisode = this.publicFeedEpisodes.filter((obj: any) => obj.guid === this.audioTrack.guid)[0]
      if (!selectedEpisode) {
        this.pushNotification({
          text: 'Please try again later',
          type: 'WARNING',
        })
        return
      }
      selectedEpisode['isRss'] = true
      this.$store.commit('unselectAllEpisodeForUpload')
      this.$store.commit('clearYoutubeState')
      this.$store.commit('resetYoutubeSetting')
      this.$store.commit('selectEpisodeForUpload', selectedEpisode)
      this.$store.commit('setYoutubeStep', 2)

      // @ts-ignore
      sessionStorage.setItem('upload', 'YES')
      sessionStorage.removeItem('onboardingTrack')
      sessionStorage.removeItem('ytModalSetting')
      this.$router.push('/onboarding')
    }
  }

  handleEditClick() {
    if (this.sourceComponent === 'ALLAUDIO-TAB') {
      this.handleEditAudioDetails()
    } else {
      this.handleEditEpisodeDetails()
    }
  }

  invalidate() {
    this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
    this.queryClient.invalidateQueries([audioQueryKeys.YTPUBLISHED, `${this.podcast.uid}`])
  }

  openEpisodeWebsite() {
    window.open(this.portraitExperienceSrc, '_blank')
  }
}
