



















































































































































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import get from 'lodash.get'
import { feedQueryKeys } from '@/hooks/rssFeeds'
import Invalidate from '@/mixins/Invalidate'
import Billing from '@/mixins/Billing'

@Component
export default class InformationTray extends Mixins(Invalidate, Billing) {
  @Prop() list!: any
  @Prop({ type: Number, default: 112.8 })
  imgWidth!: number
  @Prop({ type: Number, default: 112.8 })
  imgHeight!: number
  @Prop() selectedItem!: any
  @Prop() onButtonClick!: (onButtonClick: string) => void
  @Prop({ type: Boolean, default: false })
  isButtonVisible!: boolean
  @Prop({ type: String, default: 'OK' })
  buttonName!: string
  @Prop({ type: Boolean, default: false })
  isVisible!: boolean
  @Prop({ type: Boolean, default: false })
  showCarousal!: boolean
  @Prop() onChangeCarousal!: (onChangeCarousal: string) => void
  @Prop({ type: Boolean, default: false })
  isBackVisible!: boolean
  @Prop() onBackClick!: (onBackClick: string) => void
  @Prop({ type: Boolean, default: false })
  isInline!: boolean
  @Prop({ type: Number, default: -1 })
  count!: number
  @Prop({ type: Boolean, default: false })
  isLoading!: boolean
  @Prop(Boolean) fromYoutube!: boolean

  @Prop({ type: String, default: '' })
  rssURL!: string

  // getting the podcast details
  @Prop() podcast!: any

  @Getter selectedRssFeedUid!: any
  @Getter rssFeedItemsByFeedUid!: any
  @Getter networkType!: string
  @Getter networkId!: string
  @Getter rssFeedUids!: any
  @Getter publicFeedEpisodes!: any
  @Getter rssFeed!: any
  @Getter allAutomations!: any

  @Action clearAllRssFeedItemsByFeedUid!: any
  @Action updateRssFeedItems!: any

  @Action closeModal!: any
  @Action removeYoutubeFeed!: any
  @Action getYTRssFeeds!: any
  @Action setShowLoader!: any
  @Action unselectAllAudios!: any
  @Action clearSelectedAudioCollectionId!: any
  @Action setSelectedRssFeedUid!: any
  @Action decrementRssFeedsCount!: any
  @Action clearSelectedRssFeedUid!: any
  @Action getPublicFeedEpisodes!: any

  @Action pushNotification!: any

  // Auto publish
  @Getter autoPublishFeed!: any
  @Action getAutoPublishData!: any
  @Action enableDisableAutoPublish!: any
  @Action toggleCreditCardAlert!: any

  selectedItemIndex: any = this.list.findIndex((l: any) => l.uid === this.selectedItem)

  loadingEpisodes: boolean = false

  mounted() {
    this.$store.commit('setAutoPublishFeedData', this.isAutomationAdded ? this.currentFeedAutomation : {})
  }

  @Watch('list')
  onListChanged() {
    this.selectedItemIndex = this.list.findIndex((l: any) => l.uid === this.selectedItem)
  }

  currentKeyIndex() {
    return this.list.findIndex((l: any) => l.uid === this.selectedItem)
  }

  // TODO: check if youtube metered account is free or not
  get isFreeAcc() {
    return ['VIDEO_FREE', 'YOUTUBE_METERED'].includes(this.networkType)
  }

  get showPrev() {
    if (!this.isBackVisible) {
      return this.currentKeyIndex() !== 0
    }
    return this.selectedItemIndex !== 0
  }

  get showNext() {
    if (!this.isBackVisible) {
      return this.currentKeyIndex() !== this.list.length - 1
    } else {
      const items = this.rssFeedItemsByFeedUid[this.selectedRssFeedUid]
      return this.selectedItemIndex < items.count - 1
    }
  }

  get showMenu() {
    const addAutoPublish = {
      name: 'Add Auto Publish',
      icon: 'ondemand_video',
      onClick: () => this.goToAutoPublish('create'),
    }

    const editAutoPublish = {
      name: 'Edit Auto Publish',
      icon: 'ondemand_video',
      onClick: () => this.goToAutoPublish(),
    }
    const enableAutoPublish = {
      name: 'Enable Auto Publish',
      icon: 'delete',
      onClick: () => this.enableAutoPublish(),
    }

    const deleteAutoPublish = {
      name: 'Disable Auto Publish',
      icon: 'delete',
      onClick: () => this.disableAutoPublish(),
    }

    const removeShow = {
      name: 'Remove Show',
      icon: 'delete',
      onClick: () => this.removeShow(),
    }

    return !this.isAutomationAdded
      ? [removeShow]
      : this.isAutomationsEnabled
      ? [deleteAutoPublish, removeShow]
      : [enableAutoPublish, removeShow]
  }

  get publishStatusText() {
    return this.isAutomationAdded && this.isAutomationsEnabled
      ? 'Edit Auto Publish'
      : this.isAutomationAdded && !this.isAutomationsEnabled
      ? 'Automation Disabled'
      : 'Add Auto Publish'
  }

  get automationIcon() {
    return this.isAutomationAdded && this.isAutomationsEnabled
      ? 'auto_awesome'
      : this.isAutomationAdded && !this.isAutomationsEnabled
      ? 'auto_fix_off'
      : 'auto_mode'
  }

  handleAddAutomation() {
    if (this.publishStatusText === 'Add Auto Publish') {
      this.goToAutoPublish('create')
    } else if (this.publishStatusText === 'Edit Auto Publish') {
      this.goToAutoPublish()
    }
  }

  get isAutomationAdded() {
    return get(this.podcast, 'automations.length') ? true : false
  }

  get isAutomationsEnabled() {
    return this.isAutomationAdded
      ? this.podcast.automations.filter((obj: any) => obj.type === 'youtube')[0].isEnabled
      : false
  }

  get currentFeedAutomation() {
    return this.isAutomationAdded ? this.podcast.automations.filter((obj: any) => obj.type === 'youtube')[0] : null
  }

  async enableAutoPublish() {
    await this.enableDisableAutoPublish({
      networkId: this.networkId,
      rssFeedUid: this.selectedRssFeedUid,
      autoPublishId: get(this.podcast, 'automations[0].id'),
      isEnabled: true,
    })

    await this.getAutoPublishData({
      networkId: this.networkId,
      rssFeedUid: this.selectedRssFeedUid,
      autoPublishId: get(this.podcast, 'automations[0].id'),
    })

    this.queryClient.invalidateQueries(feedQueryKeys.YTFEED)

    this.updateRssFeed()

    this.pushNotification({
      text: 'Autopublish enabled',
      type: 'SUCCESS',
    })
  }

  disableAutoPublish() {
    this.$store.dispatch('showConfirm', {
      title: 'Disable Auto Publish?',
      description: 'Are you sure you want to disable Auto Publish?',
      yesButtonText: 'Yes',
      onConfirm: async () => {
        try {
          await this.enableDisableAutoPublish({
            networkId: this.networkId,
            rssFeedUid: this.selectedRssFeedUid,
            autoPublishId: get(this.podcast, 'automations[0].id'),
            isEnabled: false,
          })
          this.closeModal()

          await this.getAutoPublishData({
            networkId: this.networkId,
            rssFeedUid: this.selectedRssFeedUid,
            autoPublishId: get(this.podcast, 'automations[0].id'),
          })

          this.queryClient.invalidateQueries(feedQueryKeys.YTFEED)

          this.updateRssFeed()

          this.pushNotification({
            text: 'Autopublish disabled',
            type: 'SUCCESS',
          })
        } catch (err) {}
      },

      onCancel: () => {
        this.closeModal()
      },
    })
  }

  updateRssFeed() {
    const youtubeAutomationIndex = this.podcast.automations.findIndex((obj: any) => obj.type === 'youtube')
    const automations = [...this.podcast.automations]
    automations[youtubeAutomationIndex] = this.autoPublishFeed
    const updatePodcastPayload = {
      rssFeed: {
        ...this.podcast,
        automations,
      },
    }
    this.$store.commit('updateRssFeed', updatePodcastPayload)
  }

  async goToAutoPublish(action?: string) {
    if (this.showPopup) {
      this.toggleCreditCardAlert(true)
      return 0
    }
    if (
      action === 'create' &&
      this.$env.windowOrigin() === this.$env.envConstProd() &&
      this.networkType !== 'YOUTUBE_ENTERPRISE'
    ) {
      const automationLen = get(this.allAutomations, 'youtube.length', 0)
      if (automationLen >= 1) {
        this.$store.dispatch('showConfirm', {
          title: 'Upgrade Network',
          description: 'Please upgrade your network in order to add automation to more than one podcast',
          yesButtonText: 'Contact us to upgrade your network',
          noButtonText: 'No, cancel',
          onConfirm: async () => {
            window.open('https://adorilabs.com/#contact', '_blank')
            this.closeModal()
          },
        })
        return
      }
    }

    this.updateRssFeed()
    this.$store.commit('setCurrentPodcast', this.rssFeed(this.selectedRssFeedUid))
    this.$store.commit('setCreatedYoutubeFeed', { uid: this.selectedRssFeedUid })
    this.$router.push('/autopublish')
  }

  removeShow() {
    this.$store.dispatch('showConfirm', {
      title: 'Remove show?',
      description: 'Are you sure you want to remove the show?',
      yesButtonText: 'Yes',
      onConfirm: async () => {
        try {
          this.setShowLoader(true)
          const rssFeedUid = this.selectedRssFeedUid
          this.unselectAllAudios()
          this.clearSelectedRssFeedUid()
          this.clearSelectedAudioCollectionId()

          const nearestRssFeedUid = this.rssFeedUids[0] === rssFeedUid ? this.rssFeedUids[1] : this.rssFeedUids[0]
          this.setSelectedRssFeedUid(nearestRssFeedUid)

          await this.removeYoutubeFeed({
            rssFeedUid: rssFeedUid,
          })
          this.queryClient.invalidateQueries(feedQueryKeys.YTFEED)
          this.$store.commit('removeRssFeed', { rssFeedUid })
          this.decrementRssFeedsCount()
        } catch (err) {}
        this.setShowLoader(false)
        this.closeModal()
      },
    })
  }

  onSelectPrev() {
    if (this.showPrev) {
      // this.clearAllRssFeedItemsByFeedUid()
      this.selectedItemIndex = this.currentKeyIndex() - 1
      if (this.onChangeCarousal) {
        this.onChangeCarousal(this.list[this.selectedItemIndex].uid)
      }
    }
  }

  async onSelectNext() {
    if (this.showNext) {
      this.clearAllRssFeedItemsByFeedUid()
      this.selectedItemIndex = this.currentKeyIndex() + 1
      if (this.onChangeCarousal) {
        this.onChangeCarousal(this.list[this.selectedItemIndex].uid)
      }
    }
    const items = this.rssFeedItemsByFeedUid[this.selectedRssFeedUid]
    if (
      items &&
      items.data &&
      items.data.length < items.count &&
      this.selectedItemIndex > items.data.length - 10 &&
      !this.loadingEpisodes
    ) {
      this.loadingEpisodes = true
      this.loadingEpisodes = false
    }
  }

  onBack() {
    this.onBackClick(this.list[this.selectedItemIndex].uid)
  }

  @Watch('selectedItem')
  onSelectedItemChanged() {
    this.selectedItemIndex = this.list.findIndex((l: any) => l.uid === this.selectedItem)
  }
}
