











































































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { toReadableDate } from '@/utils/time'
import { pluralize } from '@/utils/misc'
import { ADORI_API_BASE_URL } from '@/constants'
import YoutubeUploadStatus from '../Youtube/YoutubeUploadStatus.vue'
import { secondsToHMS } from '@/components/Youtube/time'

// modals
import ShareModal from '@/components/Audio/Modals/Share.vue'

import { audioQueryKeys, useGetVideoClipsByTrackId } from '@/hooks/audio'
import { QueryClient, useQueryClient } from 'vue-query'
import { computed } from '@vue/composition-api'

import get from 'lodash.get'
import Billing from '@/mixins/Billing'
import mixpanel from 'mixpanel-browser'

// @ts-ignore
const webengage = window['webengage']

@Component({
  components: {
    ShareModal,
    YoutubeUploadStatus,
  },
  setup(props: any) {
    const audioTrackId: any = computed(() => props.audioTrack.uid)
    const queryClient = useQueryClient()
    const { data: videoClipsData, isLoading: videoClipsLoading } = useGetVideoClipsByTrackId(audioTrackId)

    return {
      queryClient,
      videoClipsData,
      videoClipsLoading,
    }
  },
})
export default class YoutubeAudioTrackV2 extends Mixins(Billing) {
  @Prop() audioTrack!: any
  @Prop(Boolean) selectable!: boolean
  @Prop(String) selectionType!: string // One of 'SINGLE' or 'MULTI'
  @Prop(Boolean) editable!: boolean
  @Prop(Boolean) showEditButtons!: boolean
  @Prop(Boolean) showPreviewButton!: boolean
  @Prop(Boolean) showDownloadButton!: boolean
  @Prop(String) sourceComponent!: string
  @Prop() podcast!: any

  @Getter networkId!: any
  @Getter token!: any
  @Getter signinStatus!: any
  @Getter publicFeedEpisodes!: any
  @Getter tagPositions!: any
  @Getter selectedProduct!: string

  @Action showEditPodcastEpisode!: any
  @Action closeModal!: any
  @Action deleteYoutubeAudio!: any
  @Action pushNotification!: any
  @Action getPublicFeedEpisodes!: any
  @Action toggleCreditCardAlert!: any
  @Action showIframeEmbed!: any
  @Action showReplaceAudio!: any

  shareAudio = false
  isComboBoxActive = false

  original = 'ORIGINAL'

  videoClipsData!: any
  videoClipsLoading!: any
  queryClient!: QueryClient

  mounted() {
    this.$store.commit('addAudio', this.audioTrack)
    sessionStorage.getItem(this.audioTrack.uid) === this.audioTrack.uid &&
      this.queryClient.invalidateQueries(audioQueryKeys.TRACKVIDEOCLIPS)
    sessionStorage.removeItem(this.audioTrack.uid)
  }

  get audioIsGettingReplaced() {
    if (!this.audioTrack) return false
    return (
      this.audioTrack.replaceTask &&
      this.audioTrack.replaceTask.status !== 'FINISHED' &&
      this.audioTrack.replaceTask.status !== 'FAILED'
    )
  }

  get trackTags() {
    return this.tagPositions(this.audioTrack.uid)
  }

  get youtubeSignInStatus() {
    if (get(this.audioTrack, 'ytFeed') === null) {
      return get(this.signinStatus, `[${this.networkId}].youtube`, false)
    } else {
      return (
        get(this.signinStatus, `[${get(this.podcast, 'uid', '')}].youtube`, false) ||
        get(this.signinStatus, `[${this.networkId}].youtube`, false)
      )
    }
  }

  get audioPreviewImage() {
    return this.audioTrack && this.audioTrack.imageInfo ? this.audioTrack.imageInfo.thumbnailURL : null
  }

  get audioDate() {
    return toReadableDate(this.audioTrack.createdOn)
  }

  get audioDuration() {
    if (this.audioTrack.durationMillis) {
      return secondsToHMS(this.audioTrack.durationMillis / 1000)
    }
    return 'Calculating...'
  }

  get isVideoPodcast() {
    return this.audioTrack.videoUrl ? true : false
  }

  get clipsCount() {
    return this.videoClipsData?.data.length == 1 || this.videoClipsData?.data.length == 0
      ? get(this.videoClipsData, 'data.length', 'No') + ' video clip'
      : get(this.videoClipsData, 'data.length', 'No') + ' video clips'
  }

  get firstClip() {
    return this.videoClipsData?.data[0]
  }

  get allVideoClipsExceptFirst() {
    return this.videoClipsData?.data.slice(1)
  }

  get audioTagsText() {
    if (!!this.trackTags) {
      return this.trackTags.length
        ? `${this.trackTags.length} ${pluralize(this.trackTags.length, 'tag', 'tags')} added`
        : 'No tags added'
    }
    if (!this.audioTrack?.tagCount) return 'No tags added'
    return `${this.audioTrack?.tagCount} ${pluralize(this.audioTrack?.tagCount, 'tag', 'tags')} added`
  }

  get showComboBox() {
    return this.videoClipsData?.count > 1 ? true : false
  }

  get audioTrackMenu() {
    const menu: any = []
    this.$permissions.isYoutubeConvertVideoShowEpisodeAllowed(get(this.podcast, 'uid', '')) &&
      menu.push(
        {
          name: 'Edit Episode',
          icon: 'edit',
          onClick: () => this.handleEditEpisodeDetails(),
        },
        {
          name: 'Embed on web',
          icon: 'stay_primary_portrait',
          onClick: () => this.handleEmbedWeb(),
        },
        {
          name: 'Share this track',
          icon: 'share',
          onClick: () => this.handleShareAudio(),
        }
      )

    menu.push({
      name: 'Download Audio',
      icon: 'cloud_download',
      onClick: () => (window.location.href = this.audioDownloadURL()),
    })

    this.$permissions.isDeleteEpisodeShowAllowed(get(this.podcast, 'uid', '')) &&
      menu.push(
        {
          name: 'Remove episode',
          icon: 'delete',
          onClick: () => this.removeEpisode(),
        },
        {
          name: 'Replace audio',
          icon: 'swap_vert',
          onClick: () => this.handleReplaceAudio(),
        }
      )

    return menu
  }

  get selectionIcon() {
    return this.$store.getters.isAudioSelected(this.audioTrack.uid) ? 'check_circle' : 'radio_button_unchecked'
  }

  get selectionStyle() {
    return this.$store.getters.isAudioSelected(this.audioTrack.uid) ? 'adori-red' : 'o-20'
  }

  handleReplaceAudio() {
    this.showReplaceAudio(this.audioTrack.uid)
  }

  handleShareAudio() {
    this.shareAudio = true
  }

  closeShare() {
    this.shareAudio = false
  }

  async removeEpisode() {
    this.$store.dispatch('showConfirm', {
      title: 'Remove episode?',
      description: 'Are you sure you want to remove the episode?',
      yesButtonText: 'Yes',
      onConfirm: async () => {
        try {
          this.closeModal()
          await this.deleteYoutubeAudio({
            audioUid: this.audioTrack.uid,
            ytFeedUid: this.audioTrack.ytFeed && this.audioTrack.ytFeed.uid,
          })
          this.pushNotification({
            text: 'Episode removed successfully',
            type: 'SUCCESS',
          })
          this.sourceComponent === 'ALLAUDIO-TAB' && this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
          if (this.sourceComponent === 'PODCASTS-TAB') {
            this.queryClient.invalidateQueries([audioQueryKeys.YTPUBLISHED, `${this.podcast.uid}`])
            this.queryClient.invalidateQueries([audioQueryKeys.YTUNPUBLISHED, `${this.podcast.uid}`])
          }
        } catch (err) {}
        this.closeModal()
      },
    })
  }

  handleEmbedWeb() {
    this.showIframeEmbed(this.audioTrack.uid)
  }

  handleEditEpisodeDetails() {
    this.showEditPodcastEpisode(this.audioTrack.uid)
  }

  handleEditAudio() {
    this.$router.push('/youtube/audio-editor?homepage=true&uid=' + this.audioTrack.uid)
  }

  audioDownloadURL(type?: string) {
    const networkId = this.$store.getters.networkId

    return type === this.original
      ? `${ADORI_API_BASE_URL}/networks/${networkId}/audiotracks/${this.audioTrack.uid}/audio/download?api_token=${
          this.token
        }&original=${true}`
      : `${ADORI_API_BASE_URL}/networks/${networkId}/audiotracks/${this.audioTrack.uid}/audio/download?api_token=${this.token}`
  }

  // Todo: When implementing for Studio continue here
  handleAudioClicked() {
    this.$permissions.isYoutubeConvertVideoShowEpisodeAllowed(get(this.podcast, 'uid', '')) && this.goToOnboarding()
  }
  // Todo: When implementing for Studio continue here
  handleAudioSelectionIconClicked() {}

  async goToOnboarding() {
    mixpanel.track('New Video')

    webengage?.track('new-video', { action: 'New Video copy will be created' })
    this.$gtag.event('new-video', { action: 'New Video copy will be created' })
    if (this.showPopup) {
      this.toggleCreditCardAlert(true)
      return 0
    }

    if (this.audioTrack && this.selectedProduct === 'BLOG') {
      this.$router.push(`/preview/${this.audioTrack.uid}`)
      return
    }

    if (this.audioTrack && this.audioTrack.ytFeed === null) {
      const selectedEpisode: any = {
        audioUrl: this.audioTrack.audioUrl,
        name: this.audioTrack.name,
        description: this.audioTrack.description,
        explicitContent: this.audioTrack.explicitContent,
        guid: this.audioTrack.guid,
        imageId: this.audioTrack.imageId,
        imageUrl: this.audioTrack.imageUrl,
        isRss: false,
        keywords: this.audioTrack.keywords,
        durationSeconds: this.audioTrack.durationMillis / 1000,
        isReUpload: true,
        videoUrl: this.audioTrack.videoUrl,
      }

      this.$store.commit('unselectAllEpisodeForUpload')
      this.$store.commit('clearYoutubeState')
      this.$store.commit('resetYoutubeSetting')
      this.$store.commit('selectEpisodeForUpload', selectedEpisode)
      this.$store.commit('setYoutubeStep', 2)
      sessionStorage.setItem('upload', 'YES')
      sessionStorage.setItem('onboardingTrack', JSON.stringify(selectedEpisode))
      this.$router.push('/onboarding')
    } else {
      this.$store.commit('setCreatedYoutubeFeed', this.audioTrack.ytFeed)
      await this.getPublicFeedEpisodes({
        url: this.podcast.rss,
      })
      sessionStorage.setItem('feedUrl', this.podcast.rss)
      const selectedEpisode = this.publicFeedEpisodes.filter((obj: any) => obj.guid === this.audioTrack.guid)[0]

      if (!selectedEpisode) {
        this.pushNotification({
          text: 'Please try again later',
          type: 'WARNING',
        })
        return
      }
      selectedEpisode['isRss'] = true
      this.$store.commit('unselectAllEpisodeForUpload')
      this.$store.commit('clearYoutubeState')
      this.$store.commit('resetYoutubeSetting')
      this.$store.commit('selectEpisodeForUpload', selectedEpisode)
      this.$store.commit('setYoutubeStep', 2)
      sessionStorage.setItem('upload', 'YES')

      sessionStorage.removeItem('onboardingTrack')
      sessionStorage.removeItem('ytModalSetting')
      this.$router.push('/onboarding')
    }
  }
}
